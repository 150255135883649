<template>
    <div>
        <!-- <el-card class="box-card"> -->
            <customer-form ref="child"></customer-form>
        <!-- </el-card> -->
        <div class="f-text-right">
            <el-button type="primary" size="medium" icon="el-icon-s-promotion" @click="handleAdd"

                       v-if="buttonShowList.save"
            >保存</el-button>
            <el-button size="medium" plain @click="hadleReset()">重置</el-button>
        </div>
<!--        <el-card>-->
<!--            <customer-detail-form></customer-detail-form>-->
<!--        </el-card>-->
    </div>
</template>

<script>
import CustomerForm from './CustomerForm'
// import CustomerDetailForm from "./CustomerDetailForm";
import {postAction,putAction} from "@/api/manage";
import {getUrlKey} from "@/utils/route";
import {updatedDict} from "../../../utils/util";

export default {
    name: 'BasicModule',
    components: {
        CustomerForm
    },
    data() {
        return {
          btnDisable:false,
            row: {},

          url:{
              add:'/customer/addCustomer',
              edit:'/customer/edit',

          },
          props:{
            userName:{
              type :String
            }
          }

        }
    },
  methods:{
    handleAdd(){
      //获取表单提交的数据
      this.btnDisable=true;
      var form = this.$refs.child.form;


      if (form == 'undefined'){
        this.$message({
          type: 'error',
          message: '表单数据为空',
        })
        this.btnDisable=false;
        return false;
      }
      if (form.fileList){
          form.fileList.map(f=>{
              f.ossId = f.id;
              f.fileType ='2';

          })
      }
      if (form.bussinessTypeList){
          form.bussinessType = form.bussinessTypeList.join(",");
      }

      form.id =getUrlKey('id');
      //根据新增或者编辑，修改修改url
      let flag = this.$refs['child'].validateForm();
        if (flag){
          if (!form.id){
            let url = this.url.add;
            postAction(url,form).then((res)=>{
              if (res.success){
                this.$message({
                  type: 'success',
                  message: res.message
                })
                //新增跳回公海页面
                // this.$router.push('/customer/Undetermined');
                  //刷新当前页面
                  // location.reload();
                  if (this.$refs.child) {
                      this.$refs.child.initForm();
                  }
                this.btnDisable = false;
              }else {
                this.btnDisable = false;
              }
            }).catch(err=>{
                this.btnDisable = false;
            })
          }else {
            let url = this.url.edit;
            putAction(url,form).then(res=>{
              if (res.success){
                this.$message({
                  type: 'success',
                  message: res.message
                })
                // this.$router.go(-1);
                  //刷新当前页面
                  if (this.$refs.child) {
                      this.$refs.child.initForm();
                  }
                this.btnDisable = false;
              }else {
                this.btnDisable = false;
              }
            }).catch(err=>{
              this.btnDisable = false;
            })
          }

        }else {
          this.btnDisable=false;
        }


    },
    hadleReset(){
      this.$refs.child.resetForm();
    }

  },
    created() {

    },
  computed: {
    buttonShowList() {
      return {
        'save':this.$btnIsShow('customer/Edit','0','保存'),
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
